.wrapper-input {
  @apply relative;
}
.input {
  @apply bg-HRB_gray rounded-none h-12 w-full border-solid px-2 focus:outline-none focus:border-none font-normal leading-6 text-left not-italic tracking-normal text-sm placeholder:text-sm placeholder:text-black;
  border-color: #f5f5f5;
}
.input-label {
  @apply absolute font-normal text-xs leading-5 text-left not-italic tracking-normal top-1 left-2;
}
.input-label-prefix {
  @extend .input-label;
  @apply bg-white;
  top: -0.5rem;
}
